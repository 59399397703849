import React, { useState, useEffect } from "react";
import axios from "axios";
import { Elements } from "@stripe/react-stripe-js";
import { CheckoutForm } from "./CheckoutForm";
import { IconLoading } from "../IconLoading";
import { URL_SUBSCRIBE_CREATE, URL_SUBSCRIBE_INTENT } from "../../config";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useUserData } from "../../context/useUserData";
import { SuccessOrAlertMessage } from "../AlertMessage";
import { getPricingMap } from "../../routes/pricing/dataPricing";
import { useLocationInfo } from "../../context/useLocation";
import { selectCurrency } from "../../routes/pricing/subscriptionHelpers";
import { useWeekly } from "../../context/useWeekly";
import { useStripeHook } from "../../context/useStripe";

export const SubscribeCheckout = () => {
  const { stripe, initStripe } = useStripeHook();

  const navigate = useNavigate();
  const location = useLocation();
  const { subscriptionName } = useParams();

  const { userData, loading, fetchUserData, token } = useUserData();
  // const { isWeekly } = useWeekly();
  const { isWeekly } = true;
  const { isLoading: isLoadingLocationInfo, location: locationInfo } =
    useLocationInfo();

  const [clientSecret, setClientSecret] = useState(null);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const isProd = process.env.REACT_APP_STRIPE_ENV === "prod";
  const subscriptionMap = getPricingMap(isProd, isWeekly);
  const subscriptionData = subscriptionMap[subscriptionName] || {};

  const currency = selectCurrency(locationInfo);
  const planId = subscriptionData.planId?.[currency.name] || "";

  const isLoggedIn = Boolean(userData?.uid);
  const isValidRoute = ["Lite", "Pro", "Premium", "Test"].includes(
    subscriptionName
  );

  useEffect(() => {
    initStripe();
  }, []);

  useEffect(() => {
    if (loading) {
      return;
    }

    if (!isLoggedIn) {
      navigate("/sign-up", { state: { from: location.pathname } });
    }

    if (!isValidRoute) {
      navigate("/pricing");
    }

    const fetchClientSecret = async () => {
      try {
        const reqConfig = { headers: { Authorization: token } };

        const response = await axios.get(URL_SUBSCRIBE_INTENT, reqConfig);
        setClientSecret(response?.data?.clientSecret);
      } catch (error) {
        console.error("Error fetching client secret:", error);
      }
    };

    fetchClientSecret();
  }, [loading, userData]);

  useEffect(() => {
    if (success) {
      const timer = setTimeout(async () => {
        await fetchUserData();
        navigate("/profile");
      }, 5000);

      // Cleanup function to clear the timeout if the component unmounts
      return () => clearTimeout(timer);
    }
  }, [success, navigate]);

  if (!clientSecret) return <IconLoading />;

  const options = {
    clientSecret,
  };

  const handleOkCardDetails = async (res, idCoupon) => {
    console.log(
      "Card details are okay - calling backend to create subscription",
      { res }
    );

    setIsLoading(true);
    const paymentMethodId = res?.setupIntent?.payment_method;
    const payload = {
      _id: userData?._id,
      userEmail: userData.email,
      paymentMethodId,
      planId,
      coupon: idCoupon,
    };

    try {
      const reqConfig = { headers: { Authorization: token } };
      const backendResponse = await axios.post(
        URL_SUBSCRIBE_CREATE,
        payload,
        reqConfig
      );

      const { subscription } = backendResponse.data;
      const paymentIntent = subscription?.latest_invoice?.payment_intent;

      if (
        paymentIntent?.status === "requires_action" ||
        paymentIntent?.status === "requires_payment_method"
      ) {
        const { error: stripeError } = await stripe.confirmCardPayment(
          paymentIntent.client_secret
        );

        if (stripeError) {
          console.error("Error during card payment confirmation", stripeError);
          setError("Payment failed: " + stripeError.message);
          setIsLoading(false);
          return;
        }

        setSuccess(
          "Subscription created and payment confirmed. Redirecting you in 3 seconds."
        );
      } else {
        setSuccess(
          "Subscription created successfully. Redirecting you in 3 seconds."
        );
      }
    } catch (e) {
      console.error("Error creating subscription", {
        backendError: e?.response?.data?.error,
      });
      setError(e?.response?.data?.message || "Error creating subscription");
    }
    setIsLoading(false);
  };

  if (isLoadingLocationInfo || !stripe) {
    return (
      <div className="flex flex-center text-white">
        <IconLoading message="Loading subscription plans" />
      </div>
    );
  }

  return (
    <div className="bg-gray-200 px-10 py-7 rounded-lg w-full max-w-md">
      <Elements stripe={stripe} options={options}>
        <CheckoutForm
          subscriptionData={subscriptionData}
          currency={currency}
          clientSecret={clientSecret}
          handleOkCardDetails={handleOkCardDetails}
          setError={setError}
          setIsLoading={setIsLoading}
        />
        {error && (
          <SuccessOrAlertMessage
            type="error"
            message={error}
            delaySeconds={300}
          />
        )}
        {success && (
          <SuccessOrAlertMessage
            type="success"
            message={success}
            delaySeconds={300}
          />
        )}
        {isLoading && (
          <div className="flex mt-3 rounded justify-center text-black">
            <IconLoading message="Processing subscription" />
          </div>
        )}
      </Elements>
    </div>
  );
};
